import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import Portfolio from './components/Portfolio';
import Services from './components/Services';
import About from './components/About';
import Contact from './components/Contact';
import ResidentialServices from './components/ResidentialServices';
// import CommercialServices from './components/CommercialServices';
import PhotoGallery from './components/PhotoGallery';


function App() {



  return (
    <div className="App">
      <Header />
    
          <div>
            <Routes>
              <Route exact path='/' element={<Home />}></Route>
              <Route path='/about' element={<About />}></Route>
              <Route path='/services' element={<Services />}></Route>
              <Route path='/residential-services' element={<ResidentialServices />}></Route>
              {/* <Route path='/commercial-services' element={<CommercialServices />}></Route> */}
              <Route path='/portfolio' element={<Portfolio />}></Route>
              <Route path='/photo-gallery' element={<PhotoGallery />}></Route>
              <Route path='/contact' element={<Contact />}></Route>
            </Routes>
          </div>
          <Footer />
        
    
    </div>
  );
}

export default App;
